:root {
  --ex-dialog-width: 370px;
}

.c-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--ex-dialog-width);
  background: var(--ex-bg-block);

  &--md {
    --ex-dialog-width: 500px;
  }

  &__close {
    --ex-icon-size: 12px;

    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--ex-color-step-950);
    cursor: pointer;
  }

  &__title {
    margin-top: 40px;
    margin-bottom: 32px;
    color: var(--ex-color-primary);
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    &--smallMargin {
      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 15px;
    color: var(--ex-color-step-950);
  }

  &__body {
    flex: 1;
    padding: 0 32px;
    overflow-y: auto;
  }

  &__footer {
    width: 100%;
    padding: 20px 32px 32px;

    &Btns {
      display: flex;
      align-items: center;
      justify-content: center;

      app-button {
        flex: 1;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  &--bigger {
    --ex-dialog-width: 480px;
  }

  &--onboarding {
    background-color: #293b96;
    background-image: linear-gradient(43deg, #293b96 0%, #aa50c8 46%, #76d8fe 100%);
    color: var(--ex-color-white);

    ul {
      list-style: disc;
      padding-left: 15px;

      li {
        margin-bottom: 10px;
      }
    }

    .c-dialog__title {
      color: var(--ex-color-white);
    }
  }
}
