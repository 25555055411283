:root {
  /** custom **/
  --ex-day-editor-calendar-height: 52px;
  --ex-header-height: 50px;
  --mat-icon-size: 16px;
  --ex-container-padding: 30px;
  --ex-border-radius-primary: 8px;
  --ex-color-white: #fff;
  --ex-color-black: #000;
  --ex-spinner-color: var(--ex-color-primary);
  --scrollbar-thumb: #d9d9d9;

  //transitions
  --ex-transition-primary: 200ms ease-in-out;
  --ex-transition-secondary: 150ms ease-in-out;
  --ex-transition-speed: all 150ms ease-in-out;


  /** custom backgrounds **/
  --ex-preloader-mask: rgb(51 51 51 / 51%);
  --ex-bg-body: rgba(132, 178, 255, 0.1);
  --ex-bg-block: #fff;
  --ex-theme-border-color: #d9d9d9;

  /** primary **/
  --ex-color-primary: #3377ea;
  --ex-color-primary-dark: #0e2a59;
  --ex-color-primary-light: #84b2ff;
  --ex-color-primary-rgb: 56, 128, 255;
  --ex-color-primary-contrast: #fff;
  --ex-color-primary-contrast-rgb: 255, 255, 255;
  --ex-color-primary-shade: #3171e0;
  --ex-color-primary-tint: #4c8dff;

  /** secondary **/
  --ex-color-secondary: #3dc2ff;
  --ex-color-secondary-rgb: 61, 194, 255;
  --ex-color-secondary-contrast: #fff;
  --ex-color-secondary-contrast-rgb: 255, 255, 255;
  --ex-color-secondary-shade: #36abe0;
  --ex-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ex-color-tertiary: #5260ff;
  --ex-color-tertiary-rgb: 82, 96, 255;
  --ex-color-tertiary-contrast: #fff;
  --ex-color-tertiary-contrast-rgb: 255, 255, 255;
  --ex-color-tertiary-shade: #4854e0;
  --ex-color-tertiary-tint: #6370ff;

  /** success **/
  --ex-color-success: #2dd36f;
  --ex-color-success-rgb: 45, 211, 111;
  --ex-color-success-contrast: #fff;
  --ex-color-success-contrast-rgb: 255, 255, 255;
  --ex-color-success-shade: #28ba62;
  --ex-color-success-tint: #42d77d;

  /** warning **/
  --ex-color-warning: #ffc409;
  --ex-color-warning-rgb: 255, 196, 9;
  --ex-color-warning-contrast: #000;
  --ex-color-warning-contrast-rgb: 0, 0, 0;
  --ex-color-warning-shade: #e0ac08;
  --ex-color-warning-tint: #ffca22;

  /** danger **/
  --ex-color-danger: #eb445a;
  --ex-color-danger-rgb: 235, 68, 90;
  --ex-color-danger-contrast: #fff;
  --ex-color-danger-contrast-rgb: 255, 255, 255;
  --ex-color-danger-shade: #cf3c4f;
  --ex-color-danger-tint: #ed576b;

  /** dark **/
  --ex-color-dark: #222428;
  --ex-color-dark-rgb: 34, 36, 40;
  --ex-color-dark-contrast: #fff;
  --ex-color-dark-contrast-rgb: 255, 255, 255;
  --ex-color-dark-shade: #1e2023;
  --ex-color-dark-tint: #383a3e;

  /** medium **/
  --ex-color-medium: #92949c;
  --ex-color-medium-rgb: 146, 148, 156;
  --ex-color-medium-contrast: #fff;
  --ex-color-medium-contrast-rgb: 255, 255, 255;
  --ex-color-medium-shade: #808289;
  --ex-color-medium-tint: #9d9fa6;

  /** light **/
  --ex-color-light: #f4f5f8;
  --ex-color-light-rgb: 244, 245, 248;
  --ex-color-light-contrast: #000;
  --ex-color-light-contrast-rgb: 0, 0, 0;
  --ex-color-light-shade: #d7d8da;
  --ex-color-light-tint: #f5f6f9;

  // steps
  --ex-color-step-0: #fff;
  --ex-color-step-50: #f2f2f2;
  --ex-color-step-100: #e6e6e6;
  --ex-color-step-150: #d9d9d9;
  --ex-color-step-200: #ccc;
  --ex-color-step-250: #bfbfbf;
  --ex-color-step-300: #b3b3b3;
  --ex-color-step-350: #a6a6a6;
  --ex-color-step-400: #999;
  --ex-color-step-450: #8c8c8c;
  --ex-color-step-500: #808080;
  --ex-color-step-550: #737373;
  --ex-color-step-600: #666;
  --ex-color-step-650: #595959;
  --ex-color-step-700: #4d4d4d;
  --ex-color-step-750: #404040;
  --ex-color-step-800: #333;
  --ex-color-step-850: #262626;
  --ex-color-step-900: #1a1a1a;
  --ex-color-step-950: #0d0d0d;
  --ex-color-step-1000: #000;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  // custom backgrounds
  --scrollbar-thumb: #1a1a1a;
  --ex-bg-body: #1f1f1f;
  --ex-bg-block: #333333;
  --ex-theme-border-color: #4f4f4f;
  --ex-color-primary: #428cff;
  --ex-color-primary-rgb: 66, 140, 255;
  --ex-color-primary-contrast: #fff;
  --ex-color-primary-contrast-rgb: 255, 255, 255;
  --ex-color-primary-shade: #3a7be0;
  --ex-color-primary-tint: #5598ff;

  // secondary
  --ex-color-secondary: #50c8ff;
  --ex-color-secondary-rgb: 80, 200, 255;
  --ex-color-secondary-contrast: #fff;
  --ex-color-secondary-contrast-rgb: 255, 255, 255;
  --ex-color-secondary-shade: #46b0e0;
  --ex-color-secondary-tint: #62ceff;

  // tertiary
  --ex-color-tertiary: #6a64ff;
  --ex-color-tertiary-rgb: 106, 100, 255;
  --ex-color-tertiary-contrast: #fff;
  --ex-color-tertiary-contrast-rgb: 255, 255, 255;
  --ex-color-tertiary-shade: #5d58e0;
  --ex-color-tertiary-tint: #7974ff;

  // success
  --ex-color-success: #2fdf75;
  --ex-color-success-rgb: 47, 223, 117;
  --ex-color-success-contrast: #000;
  --ex-color-success-contrast-rgb: 0, 0, 0;
  --ex-color-success-shade: #29c467;
  --ex-color-success-tint: #44e283;

  // warning
  --ex-color-warning: #ffd534;
  --ex-color-warning-rgb: 255, 213, 52;
  --ex-color-warning-contrast: #000;
  --ex-color-warning-contrast-rgb: 0, 0, 0;
  --ex-color-warning-shade: #e0bb2e;
  --ex-color-warning-tint: #ffd948;

  // danger
  --ex-color-danger: #ff4961;
  --ex-color-danger-rgb: 255, 73, 97;
  --ex-color-danger-contrast: #fff;
  --ex-color-danger-contrast-rgb: 255, 255, 255;
  --ex-color-danger-shade: #e04055;
  --ex-color-danger-tint: #ff5b71;

  // dark
  --ex-color-dark: #f4f5f8;
  --ex-color-dark-rgb: 244, 245, 248;
  --ex-color-dark-contrast: #000;
  --ex-color-dark-contrast-rgb: 0, 0, 0;
  --ex-color-dark-shade: #d7d8da;
  --ex-color-dark-tint: #f5f6f9;

  // medium
  --ex-color-medium: #989aa2;
  --ex-color-medium-rgb: 152, 154, 162;
  --ex-color-medium-contrast: #000;
  --ex-color-medium-contrast-rgb: 0, 0, 0;
  --ex-color-medium-shade: #86888f;
  --ex-color-medium-tint: #a2a4ab;

  // light
  --ex-color-light: #222428;
  --ex-color-light-rgb: 34, 36, 40;
  --ex-color-light-contrast: #fff;
  --ex-color-light-contrast-rgb: 255, 255, 255;
  --ex-color-light-shade: #1e2023;
  --ex-color-light-tint: #383a3e;
  --ex-background-color: #000;
  --ex-background-color-rgb: 0, 0, 0;

  // colors
  --ex-text-color: #fff;
  --ex-text-color-rgb: 255, 255, 255;

  // steps
  --ex-color-step-0: #000;
  --ex-color-step-50: #0d0d0d;
  --ex-color-step-100: #1a1a1a;
  --ex-color-step-150: #262626;
  --ex-color-step-200: #333;
  --ex-color-step-250: #404040;
  --ex-color-step-300: #4d4d4d;
  --ex-color-step-350: #595959;
  --ex-color-step-400: #666;
  --ex-color-step-450: #737373;
  --ex-color-step-500: #808080;
  --ex-color-step-550: #8c8c8c;
  --ex-color-step-600: #999;
  --ex-color-step-650: #a6a6a6;
  --ex-color-step-700: #b3b3b3;
  --ex-color-step-750: #bfbfbf;
  --ex-color-step-800: #ccc;
  --ex-color-step-850: #d9d9d9;
  --ex-color-step-900: #e6e6e6;
  --ex-color-step-950: #f2f2f2;
  --ex-color-step-1000: #fff;
  // mat colors
  --mat-menu-container-color: #333333;
  --mat-menu-item-label-text-color: #fff;
  --mat-menu-item-icon-color: #fff;
  --mdc-list-list-item-label-text-color: var(--ex-color-primary-contrast);
  --mdc-list-list-item-hover-label-text-color: var(--mat-menu-item-label-text-color);
  --mdc-filled-text-field-container-color: #1f1f1f;
  --mat-select-enabled-arrow-color: var(--ex-color-step-600);
  --mat-divider-color: var(--ex-theme-border-color);
  --mdc-form-field-label-text-color: #fff;

  // backgrounds
  --ex-item-background: #000;
  --ex-card-background: #1c1c1d;

  * {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #808080;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #808080;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #808080;
    }
  }
}
