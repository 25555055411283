:root {
  --ex-priority-bg: transparent;
  --ex-priority-after-color: transparent;
}

.o-priorityBadge {
  --mat-icon-size: 12px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: var(--ex-priority-bg);
  color: var(--ex-color-white);
  border-radius: 50%;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background: var(--ex-priority-after-color);
    color: var(--ex-color-white);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &--urgent {
    --ex-priority-after-color: #e82f4a;
    --ex-priority-bg: rgba(212, 60, 82, 0.2);
  }

  &--high {
    --ex-priority-after-color: #fa8f54;
    --ex-priority-bg: rgba(223, 149, 61, 0.2);
  }

  &--normal {
    --ex-priority-after-color: #58c27d;
    --ex-priority-bg: rgba(0, 160, 149, 0.2);
  }

  &--low {
    --ex-priority-after-color: var(--ex-color-step-600);
    --ex-priority-bg: var(--ex-color-step-100);
  }

  .mat-icon {
    z-index: 2;
  }
}
