mat-form-field {

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
        border-right-width: 0px !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: var(--ex-color-step-950) !important;
    }
}
