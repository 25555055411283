mat-form-field {
    label {
        mat-label,
        .mat-mdc-form-field-required-marker {
            color: var(--ex-color-step-950);
        }
    }
}

.mdc-button .mdc-button__label,
.mat-mdc-outlined-button>.mat-icon {
    color: var(--ex-color-step-950);
}
