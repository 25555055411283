:root {
  --ex-btn-height: 32px;
  --ex-btn-width: 100%;
  --ex-btn-max-width: 300px;
  --ex-btn-bg: var(--ex-color-primary);
  --ex-btn-padding: 0 8px;
  --ex-btn-border-width: 1px;
  --ex-btn-border-color: transparent;
  --ex-btn-color: var(--ex-color-white);
  --ex-btn-border-radius: 4px;
  --ex-btn-font-size: 13px;
}

.btn {
  --mat-icon-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ex-btn-width);
  max-width: var(--ex-btn-maxWidth);
  height: var(--ex-btn-height);
  padding: var(--ex-btn-padding);
  background: var(--ex-btn-bg);
  color: var(--ex-btn-color);
  border-width: var(--ex-btn-border-width);
  border-style: solid;
  border-color: var(--ex-btn-border-color);
  border-radius: var(--ex-btn-border-radius);
  font-size: var(--ex-btn-font-size);
  cursor: pointer;

  &--auto {
    --ex-btn-width: auto;
  }

  &__spinner {
    display: block;
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin: auto;
    object-fit: contain;
    animation: rotate 1s infinite;
  }

  &__icon {
    margin-right: 6px;
  }

  &[disabled] {
    --ex-btn-bg: var(--ex-color-step-350);
  }

  &:hover:not([disabled]) {
    --ex-btn-bg: var(--ex-color-primary-light);
  }

  &-outline {
    --ex-btn-bg: transparent;
    --ex-btn-border-color: var(--ex-color-primary);
    --ex-btn-color: var(--ex-color-primary);

    &:hover:not([disabled]) {
      --ex-btn-border-color: var(--ex-color-primary-light);
      --ex-btn-color: var(--ex-color-primary-light);
      --ex-btn-bg: rgba(132, 178, 255, 0.1);
    }

    &[disabled] {
      --ex-btn-border-color: var(--ex-color-step-350);
      --ex-btn-color: var(--ex-color-step-350);
    }
  }

  &-gray {
    --ex-btn-color: var(--ex-color-step-500);
    --ex-btn-border-color: var(--ex-color-step-300);
  }

  &-transparent {
    --ex-btn-bg: transparent;
    --ex-btn-border-color: transparent;
    --ex-btn-color: var(--ex-color-primary);

    &:hover:not([disabled]) {
      --ex-btn-color: var(--ex-color-primary-light);
    }

    &[disabled] {
      --ex-btn-color: var(--ex-color-step-350);
    }
  }
}
