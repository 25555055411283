@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400;500&display=swap");

// tailwindcss
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

//text edotir
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// custom styles
@import "src/assets/styles/index";

:root {
  --primary: #ddd;
  --dark: #333;
  --light: #fff;
  --shadow: 0 1px 5px rgba(104, 104, 104, 0.8);
  --page-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  scrollbar-width: thin;

  &::-webkit-scrollbar-button {
    display: block;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    display: block;
    border-radius: 100px;
    transition: var(--ex-transition-primary);
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 100px;
    transition: var(--ex-transition-primary);
    background-color: var(--scrollbar-thumb);
  }
}

html {
  height: 100%;
  color: var(--dark);
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: var(--ex-color-step-950);
}

app-day-editor,
app-settings,
input-password {
  display: contents;
}

.container {
  width: 100%;
  max-width: calc(1120px + var(--ex-container-padding) * 2);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--ex-container-padding);
  padding-left: var(--ex-container-padding);
}

.text {
  &-gray {
    color: var(--ex-color-step-600) !important;
  }

  &-default {
    color: var(--ex-color-step-1000) !important;
  }
}

.o-link {
  color: var(--ex-color-primary);
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &--white {
    color: var(--ex-color-white);
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &--currentColor {
    color: currentColor;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &--lg {
    font-size: 15px;
  }

  &--center {
    text-align: center;
  }
}

.o-error {
  @apply pl-2.5 text-xs;
}

.m-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

/* Change autocomplete styles in WebKit */
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover:hover,
input:-webkit-autofill:focus:focus {
  -webkit-text-fill-color: var(--ex-color-step-950) !important;
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}